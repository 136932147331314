/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import dataTableData from "layouts/ecommerce/orders/order-list/data/dataTableData";
import Grid from "@mui/material/Grid";
import useProducts from "hooks/useProducts";
import { useMaterialUIController } from "context";
import { Link } from "react-router-dom";
import DetailButton from "./components/DetailButton";
import MDButton from "components/MDButton";

function ProductsPage() {
  const [controller, dispatch] = useMaterialUIController();
  const { period, category } = controller;

  const data = useProducts(period, category);

  const table = {
    columns: [
      { Header: "Barcode", accessor: "barcode" },
      { Header: "Category", accessor: "category_name" },
      { Header: "Subcategory", accessor: "subcategory_name" },
      { Header: "brand", accessor: "brand_name" },
      { Header: "subbrand", accessor: "subbrand_name" },
      { Header: "manufacture", accessor: "manufacture_name" },
      { Header: "variant", accessor: "variant_name" },
      { Header: "Size", accessor: "size" },
      { Header: "type", accessor: "type_name" },
      { Header: "price", accessor: "price" },
      { Header: "sales", accessor: "sales" },
      { Header: "stocks", accessor: "stocks" },
      {
        Header: "action",
        accessor: "id",
        // Cell: ({ value }) {<DetailButton id={value} />},
        Cell: e => {
          //console.log(">>>>> ", e.value);
        return (e.value && <Link to={{ pathname: `/products/${e.value}` }}><MDButton variant="gradient" color="info">Detail</MDButton></Link>)}

      },
    ],
    rows: [],
  };
  table.rows = data.products;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Products
            </MDTypography>
          </MDBox>
          {data.products && <DataTable table={table} canSearch />}
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ProductsPage;
