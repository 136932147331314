const baseConfig = {
  // rootFolder: "http://139.59.230.218/rspa/public/api/",
  rootFolder: "https://api.yretail.mn/public/api/",
};

const config = {
  imageFact: `${baseConfig.rootFolder}images/fact/`,
  imageLogo: `${baseConfig.rootFolder}images/logo/`,
  icon: `${baseConfig.rootFolder}images/icon/`,
  api: `${baseConfig.rootFolder}index.php/api/`,
};

export default config;
