/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import FormField from "layouts/ecommerce/products/new-product/components/FormField";

import config from "config";
import API from "utils/API";

function NewCompany() {
  const [name, setName] = useState("");
  const [color, setColor] = useState("");

  const [loading, setLoading] = useState(false);
  const [errortext, setErrortext] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const handleSubmitButton = () => {
    setErrortext("");
    setLoading(true);
    const formData = new FormData();

    formData.append("name", name);
    formData.append("color", color);

    setLoading(true);
    API.post(`${config.api}add_company`, formData)
      .then((res) => {
        setLoading(false);
        setErrortext("");
        /*
         */
      })
      .catch((err) => {
        setLoading(false);
        setErrortext(err.message);
      });
    /*
    for (var key in dataToSend) {
      formData.append(key, dataToSend[key]);
    }

    /*
    if (image != null) {
      let filename = image.split("/").pop();
      let match = /\.(\w+)$/.exec(filename);
      let type = match ? `image/${match[1]}` : `image`;

      formData.append("photo", { uri: image, name: filename, type });
    }
    /*
    var formBody = [];
    for (var key in dataToSend) {
      var encodedKey = encodeURIComponent(key);
      var encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    */

    /*
    fetch(`${config.api}add_company`, {
      method: "POST",
      body: formData,
      headers: {
        "Content-Type": "multipart/form-data;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setLoading(false);
        // If server response message same as Data Matched
        if (responseJson.status === "success") {
          setIsSuccess(true);
        } else {
          setErrortext("Захиалга амжилтгүй");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
    */
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  Add Company
                </MDTypography>
              </MDBox>
              <MDTypography variant="h5" fontWeight="regular" color="secondary">
                &nbsp;
              </MDTypography>
            </MDBox>
            <Card>
              <MDBox p={2}>
                <MDBox>
                  <MDBox>
                    <MDTypography variant="h5">Company Information</MDTypography>
                    <MDBox mt={3}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <FormField
                            type="text"
                            label="Name"
                            defaultValue={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormField
                            type="text"
                            label="Color"
                            length="7"
                            defaultValue={color}
                            onChange={(e) => setColor(e.target.value)}
                          />
                        </Grid>
                      </Grid>
                    </MDBox>
                    <MDBox mt={2}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                            <MDTypography
                              component="label"
                              variant="button"
                              fontWeight="regular"
                              color="text"
                            >
                              Category
                            </MDTypography>
                          </MDBox>
                          <Autocomplete
                            multiple
                            defaultValue={[]}
                            options={[]}
                            renderInput={(params) => <MDInput {...params} variant="standard" />}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <MDBox mb={3}>
                            <MDBox mb={2} display="inline-block">
                              <MDTypography
                                component="label"
                                variant="button"
                                fontWeight="regular"
                                color="text"
                                textTransform="capitalize"
                              >
                                Status
                              </MDTypography>
                            </MDBox>
                            <Autocomplete
                              defaultValue="active"
                              options={["active", "inactive"]}
                              renderInput={(params) => <MDInput {...params} variant="standard" />}
                            />
                          </MDBox>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </MDBox>

                  <MDBox mt={3} width="100%" display="flex" justifyContent="space-between">
                    <MDButton variant="gradient" color="dark" onClick={handleSubmitButton}>
                      Save
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NewCompany;
