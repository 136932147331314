/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { BrowserRouter as Router, Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import useLogs from "hooks/useLogs";

function LogsPage() {
  const table = {
    columns: [
      { Header: "Firstname", accessor: "first_name", width: "20%" },
      { Header: "Date", accessor: "date", width: "20%" },
      { Header: "IP Address", accessor: "ip_add", width: "20%" },
      { Header: "Operation", accessor: "operation", width: "20%" },
      { Header: "Description", accessor: "op_inf", width: "20%" },
    ],
    rows: [],
  };
  const data = useLogs();
  table.rows = data.logs;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Logs
            </MDTypography>
          </MDBox>
          {data.logs && <DataTable table={table} canSearch />}
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default LogsPage;
