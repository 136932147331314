/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";


// @mui material components
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";
import MDInput from "components/MDInput";

// ProductPage page components
import ProductImages from "layouts/ecommerce/products/product-page/components/ProductImages";
import ProductInfo from "layouts/ecommerce/products/product-page/components/ProductInfo";

// Data
import dataTableData from "layouts/ecommerce/products/product-page/data/dataTableData";
import { useParams } from "react-router-dom";
import useProduct from "hooks/useProduct";
import { useMaterialUIController } from "context";


function ProductPage() {

  const params = useParams();

  // console.log(">>> ", params); // 👉️ {userId: '4200'}

  const [controller, dispatch] = useMaterialUIController();
  const { period, category } = controller;
  const product = useProduct(period, params.id);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Card sx={{ overflow: "visible" }}>
          <MDBox p={3}>
            <MDBox mb={3}>
              <MDTypography variant="h5" fontWeight="medium">
                Product Details
              </MDTypography>
            </MDBox>

            <Grid container spacing={3}>
              <Grid item xs={12} lg={6} xl={5}>
                {product.image && (
                <MDBox display="flex" alignItems="center" pr={2}>
                  <img src={product.image} alt="" style={{ maxWidth: 200 }} />
                </MDBox>
                )}
              </Grid>
              <Grid item xs={12} lg={5} sx={{ mx: "auto" }}>
    {product && (
    <MDBox>
      <MDBox mb={1}>
        <MDTypography variant="h3" fontWeight="bold">
          {product.barcode}
        </MDTypography>
      </MDBox>
      <MDBox mt={1}>
        <MDTypography variant="h6" fontWeight="medium">
          Price
        </MDTypography>
      </MDBox>
      <MDBox mb={1}>
        <MDTypography variant="h5" fontWeight="medium">
          {product.price}
        </MDTypography>
      </MDBox>
      <MDBox mt={3} mb={1} ml={0.5}>
        <MDTypography variant="button" fontWeight="regular" color="text">
          Description
        </MDTypography>
      </MDBox>
      <MDBox component="ul" m={0} pl={4} mb={2}>

        <Grid container spacing={3}>
          <Grid item xs={6} sm={6}>
            <MDTypography>
              Manufacture
            </MDTypography>
          </Grid>
          <Grid item xs={6} sm={6}>
            <MDTypography variant="h6" fontWeight="medium">
              {product.manufacture_name}
            </MDTypography>
          </Grid>
          <Grid item xs={6} sm={6}>
            <MDTypography>
              Brand
            </MDTypography>
          </Grid>
          <Grid item xs={6} sm={6}>
            <MDTypography variant="h6" fontWeight="medium">
              {product.brand_name}
            </MDTypography>
          </Grid>
          <Grid item xs={6} sm={6}>
            <MDTypography>
              Subbrand
            </MDTypography>
          </Grid>
          <Grid item xs={6} sm={6}>
            <MDTypography variant="h6" fontWeight="medium">
              {product.subbrand_name}
            </MDTypography>
          </Grid>
          <Grid item xs={6} sm={6}>
            <MDTypography>
              Category
            </MDTypography>
          </Grid>
          <Grid item xs={6} sm={6}>
            <MDTypography variant="h6" fontWeight="medium">
              {product.category_name}
            </MDTypography>
          </Grid>
          <Grid item xs={6} sm={6}>
            <MDTypography>
              Subcategory
            </MDTypography>
          </Grid>
          <Grid item xs={6} sm={6}>
            <MDTypography variant="h6" fontWeight="medium">
              {product.subcategory_name}
            </MDTypography>
          </Grid>
          <Grid item xs={6} sm={6}>
            <MDTypography>
              Size
            </MDTypography>
          </Grid>
          <Grid item xs={6} sm={6}>
            <MDTypography variant="h6" fontWeight="medium">
              {product.size}
            </MDTypography>
          </Grid>
          <Grid item xs={6} sm={6}>
            <MDTypography>
              Type
            </MDTypography>
          </Grid>
          <Grid item xs={6} sm={6}>
            <MDTypography variant="h6" fontWeight="medium">
              {product.type_name}
            </MDTypography>
          </Grid>
          <Grid item xs={6} sm={6}>
            <MDTypography>
              Variant
            </MDTypography>
          </Grid>
          <Grid item xs={6} sm={6}>
            <MDTypography variant="h6" fontWeight="medium">
              {product.variant_name}
            </MDTypography>
          </Grid>
        </Grid>

      </MDBox>
    </MDBox>
    )}
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ProductPage;
