import { useState, useEffect } from "react";
import axios from "axios";
import API from "utils/API";
import useToken from "useToken";

export default (period, id) => {
  const [data, setData] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { token, setToken } = useToken();

  const formData = new FormData();
  formData.append("session_key", token);

  useEffect(() => {
    setLoading(true);
    API.post(`get_product/${id}?period=${period}`, formData)
      .then((res) => {
        setLoading(false);
        setData(res.data.product);
        setErrorMessage(null);
        /*
         */
      })
      .catch((err) => {
        setLoading(false);
        setData([]);
        setErrorMessage(err.message);
      });
  }, [period, id]);

  return data;
};
