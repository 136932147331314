/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import Grid from "@mui/material/Grid";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import { useMaterialUIController } from "context";
import { RotatingLines } from "react-loader-spinner";
import usePrice from "hooks/usePrice";

function PricePage({ type }) {
  const [controller] = useMaterialUIController();
  const { period, category } = controller;

  const title = `Price (₮) / ${type}`;
  const [data, loading] = usePrice(type, period, category);
  const chart = {
    labels: data.cols,
    datasets: {
      label: "Price",
      data: data.volumes,
    },
  };
  const table = {
    columns: data.table_header,
    rows: data.table_rows,
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="96"
          visible={loading}
        />
        <Grid item>
          <MDBox mb={3}>
            {data.cols && (
              <ReportsLineChart
                color="success"
                title={title}
                description=""
                date=""
                chart={chart}
              />
            )}
          </MDBox>
        </Grid>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              {title}
            </MDTypography>
          </MDBox>
          {data.table && <DataTable table={data.table} canSearch />}
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
PricePage.defaultProps = {
  type: "manufacture",
};

PricePage.propTypes = {
  type: PropTypes.string,
};

export default PricePage;
