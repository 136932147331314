import { useState, useEffect } from "react";
import axios from "axios";
import API from "utils/API";
import useToken from "useToken";

export default (type, value, period, category) => {
  const [data, setData] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { token, setToken } = useToken();

  const formData = new FormData();
  formData.append("session_key", token);

  useEffect(() => {
    setLoading(true);
    API.post(
      `get_sales?type=${type}&value=${value}&period=${period}&category_id=${category}`,
      formData
    )
      .then((res) => {
        setLoading(false);
        setData(res.data);
        setErrorMessage(null);
        /*
         */
      })
      .catch((err) => {
        setLoading(false);
        setData([]);
        setErrorMessage(err.message);
      });
  }, [type, value, period, category]);

  return [data, loading];
};
