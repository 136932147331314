/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

function ImageCell({ image }) {
  return (
    <MDBox display="flex" alignItems="center" pr={2}>
      <img src={image} alt="" style={{ maxWidth: 200 }} />
    </MDBox>
  );
}

// Typechecking props for the ImageCell
ImageCell.propTypes = {
  image: PropTypes.string.isRequired,
};

export default ImageCell;
