import { useState, useEffect } from "react";
import axios from "axios";
import API from "utils/API";

export default (token) => {
  const [data, setData] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const formData = new FormData();
  formData.append("session_key", token);

  useEffect(() => {
    setLoading(true);
    API.post(`init`, formData)
      .then((res) => {
        setLoading(false);
        setData(res.data);
        setErrorMessage(null);
      })
      .catch((err) => {
        setLoading(false);
        setData([]);
        setErrorMessage(err.message);
      });
  }, []);

  return [data, errorMessage, loading];
};
