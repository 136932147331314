/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { BrowserRouter as Router, Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import Grid from "@mui/material/Grid";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import reportsLineChartData from "layouts/dashboards/analytics/data/reportsLineChartData";
import useCompanies from "hooks/useCompanies";
import DefaultCell from "layouts/dashboards/sales/components/DefaultCell";
import { useMaterialUIController } from "context";
import NewCompany from "./new-company";

function CompaniesPage() {
  const data = useCompanies();

  const table = {
    columns: [
      { Header: "Name", accessor: "name" },
      { Header: "Logo", accessor: "logo" },
      { Header: "Status", accessor: "status" },
      { Header: "Color", accessor: "color" },
      { Header: "Expire Date", accessor: "expire_date" },
      {
        Header: "action",
        accessor: "id",
        // Cell: ({ value }) <DetailButton id={value} />,
      },
    ],
    rows: [],
  };
  table.rows = data;
  console.log(data);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Companies
            </MDTypography>
            <Link to="/companies/new">
              <MDButton variant="gradient" color="info">
                new company
              </MDButton>
            </Link>
          </MDBox>
          {data && <DataTable table={table} canSearch />}
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CompaniesPage;
