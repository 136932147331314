/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import BookingCard from "examples/Cards/BookingCard";

// Anaytics dashboard components
import SalesByCountry from "layouts/dashboards/analytics/components/SalesByCountry";

// Images
import booking1 from "assets/images/products/product-1-min.jpg";
import booking2 from "assets/images/products/product-2-min.jpg";
import booking3 from "assets/images/products/product-3-min.jpg";

import useOverview from "hooks/useOverview";
import { useEffect, useState } from "react";
import axios from "axios";
import { useMaterialUIController, setMiniSidenav, setPeriod } from "context";

import { RotatingLines } from "react-loader-spinner";
import config from "config";
import { toNum } from "helpers";

function Analytics() {
  const [controller, dispatch] = useMaterialUIController();
  const { period, category, brandColor } = controller;

  const [data, loading] = useOverview(period, category);

  const volumeChart = {
    labels: data.cols,
    datasets: {
      label: "Volume",
      data: data.sales_volumes,
    },
  };

  const valueChart = {
    labels: data.cols,
    datasets: {
      label: "Value",
      data: data.sales_values,
    },
  };

  const itemChart = {
    labels: data.cols,
    datasets: {
      label: "Item",
      data: data.sales_items,
    },
  };
  /*
  useEffect(() => {
    console.log("use Effect");
  }, [period]);
  const [notes, setNotes] = useState("");

  const getAllNotes = () => {
    axios
      .get(`http://rspa.local/yr/rspa/web/public/api/index.php/api/dashboardd`)
      .then((response) => {
        setNotes(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getAllNotes();
  }, []);
  */

  // Action buttons for the BookingCard
  const actionButtons = (
    <>
      <Tooltip title="Refresh" placement="bottom">
        <MDTypography
          variant="body1"
          color="primary"
          lineHeight={1}
          sx={{ cursor: "pointer", mx: 3 }}
        >
          <Icon color="inherit">refresh</Icon>
        </MDTypography>
      </Tooltip>
      <Tooltip title="Edit" placement="bottom">
        <MDTypography variant="body1" color="info" lineHeight={1} sx={{ cursor: "pointer", mx: 3 }}>
          <Icon color="inherit">edit</Icon>
        </MDTypography>
      </Tooltip>
    </>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="96"
          visible={loading}
        />
        <Grid container>{data.regions && <SalesByCountry regions={data.regions} />}</Grid>
        <MDBox mt={6}>
          {data.date && (
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={3}>
                  <ReportsBarChart
                    color="info"
                    title="Sales Volume"
                    description=""
                    date={data.date}
                    chart={volumeChart}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={3}>
                  <ReportsLineChart
                    color="success"
                    title="Sales Value"
                    description=""
                    date={data.date}
                    chart={valueChart}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={3}>
                  <ReportsLineChart
                    color="dark"
                    title="Sales Items"
                    description=""
                    date={data.date}
                    chart={itemChart}
                  />
                </MDBox>
              </Grid>
            </Grid>
          )}
        </MDBox>
        <MDBox mt={1.5}>
          <Grid container spacing={3}>
            {data.categories &&
              data.categories.map((item, index) => (
                <Grid item xs={12} md={6} lg={3}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      bgColor={brandColor}
                      icon="store"
                      title={toNum(item.sales_volume)}
                      count={item.category}
                      percentage={{
                        color: item.growth >= 0 ? "success" : "error",
                        amount: toNum(item.growth) + (item.growth == 0 ? "" : " (" + toNum(item.growth * 100 / item.sales_volume) + "%)"),
                      }}
                    />
                  </MDBox>
                </Grid>
              ))}
            {false && (
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    icon="leaderboard"
                    title="Today's Users"
                    count="2,300"
                    percentage={{
                      color: "success",
                      amount: "+3%",
                      label: "than last month",
                    }}
                  />
                </MDBox>
              </Grid>
            )}
            {false && (
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="success"
                    icon="store"
                    title="Revenue"
                    count="34k"
                    percentage={{
                      color: "success",
                      amount: "+1%",
                      label: "than yesterday",
                    }}
                  />
                </MDBox>
              </Grid>
            )}
            {false && (
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="primary"
                    icon="person_add"
                    title="Followers"
                    count="+91"
                    percentage={{
                      color: "success",
                      amount: "",
                      label: "Just updated",
                    }}
                  />
                </MDBox>
              </Grid>
            )}
          </Grid>
        </MDBox>
        <MDBox mt={2}>
          <Grid container spacing={3}>
            {data.images &&
              data.images.map((item, index) => (
                <Grid item xs={12} md={6} lg={4}>
                  <MDBox mt={3}>
                    <BookingCard
                      image={`${config.imageFact}${item.image}`}
                      title={item.type}
                      description={item.description}
                      price={item.cal_date}
                      location={item.manufacture}
                      action={actionButtons}
                    />
                  </MDBox>
                </Grid>
              ))}
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Analytics;
