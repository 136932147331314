/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import Analytics from "layouts/dashboards/analytics";
import Sales from "layouts/dashboards/sales";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import AllProjects from "layouts/pages/profile/all-projects";
import NewUser from "layouts/pages/users/new-user";
import Settings from "layouts/pages/account/settings";
import Billing from "layouts/pages/account/billing";
import Invoice from "layouts/pages/account/invoice";
import Timeline from "layouts/pages/projects/timeline";
import PricingPage from "layouts/pages/pricing-page";
import StocksPage from "layouts/pages/stocks";
import ProductsPage from "layouts/pages/products";
import CompaniesPage from "layouts/pages/companies";
import NewCompany from "layouts/pages/companies/new-company";
import Widgets from "layouts/pages/widgets";
import RTL from "layouts/pages/rtl";
import Charts from "layouts/pages/charts";
import Notifications from "layouts/pages/notifications";
import Kanban from "layouts/applications/kanban";
import Wizard from "layouts/applications/wizard";
import DataTables from "layouts/applications/data-tables";
import Calendar from "layouts/applications/calendar";
import NewProduct from "layouts/ecommerce/products/new-product";
import EditProduct from "layouts/ecommerce/products/edit-product";
import ProductPage from "layouts/pages/products/product";
import OrderList from "layouts/ecommerce/orders/order-list";
import OrderDetails from "layouts/ecommerce/orders/order-details";
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignInCover from "layouts/authentication/sign-in/cover";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import SignUpCover from "layouts/authentication/sign-up/cover";
import ResetCover from "layouts/authentication/reset-password/cover";

/*
import { useMaterialUIController } from "context";

const [controller] = useMaterialUIController();
const { user } = controller;
*/
// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/team-3.jpg";
import UsersPage from "layouts/pages/users";
import LogsPage from "layouts/pages/logs";
import SalesPage from "layouts/pages/sales";
import UserName from "layouts/dashboards/sales/components/UserName";
import FacePage from "layouts/pages/face";
import PricePage from "layouts/pages/price";
import DistributionPage from "layouts/pages/distribution";
import ManualPage from "layouts/pages/manual";
import DashboardFacing from "layouts/dashboards/facing";
import DashboardStocks from "layouts/dashboards/stocks";
import DashboardDistribution from "layouts/dashboards/distribution";
import DashboardPrice from "layouts/dashboards/price";

const routes = [
  {
    role: 4,
    type: "collapse",
    name: <UserName />,
    key: "brooklyn-alice",
    icon: <Icon fontSize="medium">account_circle</Icon>,
    collapse: [
      /*
      {
        name: "My Profile",
        key: "my-profile",
        route: "/pages/profile/profile-overview",
        component: <ProfileOverview />,
      },
      {
        name: "Settings",
        key: "profile-settings",
        route: "/pages/account/settings",
        component: <Settings />,
      },
      */
      {
        role: 4,
        name: "Logout",
        key: "logout",
        route: "/authentication/sign-in/basic",
        component: <SignInBasic />,
      },
    ],
  },
  { type: "divider", key: "divider-0" },
  {
    role: 4,
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        role: 4,
        name: "Overview",
        key: "overview",
        route: "/dashboard/overview",
        component: <Analytics />,
      },
      {
        role: 4,
        name: "Sales",
        key: "sales",
        route: "/dashboard/sales",
        component: <Sales type="sales" />,
      },
      {
        role: 4,
        name: "Stocks",
        key: "stocks",
        route: "/dashboard/stocks",
        component: <DashboardStocks type="stocks" />,
      },
      {
        role: 4,
        name: "Distribution",
        key: "distribution",
        route: "/dashboard/distribution",
        component: <DashboardDistribution type="distribution" />,
      },
      {
        role: 4,
        name: "Price",
        key: "price",
        route: "/dashboard/price",
        component: <DashboardPrice type="price" />,
      },
      {
        role: 4,
        name: "Facing",
        key: "facing",
        route: "/dashboard/facing",
        component: <DashboardFacing type="facing" />,
      },
    ],
  },
  {
    role: 4,
    type: "collapse",
    name: "Sales",
    key: "sales",
    icon: <Icon fontSize="medium">shopping_cart_checkout</Icon>,
    collapse: [
      {
        role: 4,
        name: "Manufacture",
        key: "sales-manufacture",
        collapse: [
          {
            role: 4,
            name: "Volume",
            key: "sales-manufacture-volume",
            route: "/sales/manufacture/volume",
            component: <SalesPage type="manufacture" value="volume" />,
          },
          {
            role: 4,
            name: "Value",
            key: "sales-manufacture-value",
            route: "/sales/manufacture/value",
            component: <SalesPage type="manufacture" value="value" />,
          },
          {
            role: 4,
            name: "Items",
            key: "sales-manufacture-items",
            route: "/sales/manufacture/items",
            component: <SalesPage type="manufacture" value="items" />,
          },
        ],
      },
      {
        role: 4,
        name: "Brand",
        key: "sales-brand",
        collapse: [
          {
            role: 4,
            name: "Volume",
            key: "sales-brand-volume",
            route: "/sales/brand/volume",
            component: <SalesPage type="brand" value="volume" />,
          },
          {
            role: 4,
            name: "Value",
            key: "sales-brand-value",
            route: "/sales/brand/value",
            component: <SalesPage type="brand" value="value" />,
          },
          {
            role: 4,
            name: "Items",
            key: "sales-brand-items",
            route: "/sales/brand/items",
            component: <SalesPage type="brand" value="items" />,
          },
        ],
      },
      {
        role: 4,
        name: "Segment",
        key: "sales-segment",
        collapse: [
          {
            role: 4,
            name: "Volume",
            key: "sales-segment-volume",
            route: "/sales/segment/volume",
            component: <SalesPage type="segment" value="volume" />,
          },
          {
            role: 4,
            name: "Value",
            key: "sales-segment-value",
            route: "/sales/segment/value",
            component: <SalesPage type="segment" value="value" />,
          },
          {
            role: 4,
            name: "Items",
            key: "sales-segment-items",
            route: "/sales/segment/items",
            component: <SalesPage type="segment" value="items" />,
          },
        ],
      },
    ],
  },
  {
    role: 4,
    type: "collapse",
    name: "Stocks",
    key: "stocks",
    icon: <Icon fontSize="medium">inventory_2</Icon>,
    collapse: [
      {
        role: 4,
        name: "Manufacture",
        key: "stocks-manufacture",
        collapse: [
          {
            role: 4,
            name: "Volume",
            key: "stocks-manufacture-volume",
            route: "/stocks/manufacture/volume",
            component: <StocksPage type="manufacture" value="volume" />,
          },
          {
            role: 4,
            name: "Value",
            key: "stocks-manufacture-value",
            route: "/stocks/manufacture/value",
            component: <StocksPage type="manufacture" value="value" />,
          },
          {
            role: 4,
            name: "Items",
            key: "stocks-manufacture-items",
            route: "/stocks/manufacture/items",
            component: <StocksPage type="manufacture" value="items" />,
          },
        ],
      },
      {
        role: 4,
        name: "Brand",
        key: "stocks-brand",
        collapse: [
          {
            role: 4,
            name: "Volume",
            key: "stocks-brand-volume",
            route: "/stocks/brand/volume",
            component: <StocksPage type="brand" value="volume" />,
          },
          {
            role: 4,
            name: "Value",
            key: "stocks-brand-value",
            route: "/stocks/brand/value",
            component: <StocksPage type="brand" value="value" />,
          },
          {
            role: 4,
            name: "Items",
            key: "stocks-brand-items",
            route: "/stocks/brand/items",
            component: <StocksPage type="brand" value="items" />,
          },
        ],
      },
      {
        role: 4,
        name: "Segment",
        key: "stocks-segment",
        collapse: [
          {
            role: 4,
            name: "Volume",
            key: "stocks-segment-volume",
            route: "/stocks/segment/volume",
            component: <StocksPage type="segment" value="volume" />,
          },
          {
            role: 4,
            name: "Value",
            key: "stocks-segment-value",
            route: "/stocks/segment/value",
            component: <StocksPage type="segment" value="value" />,
          },
          {
            role: 4,
            name: "Items",
            key: "stocks-segment-items",
            route: "/stocks/segment/items",
            component: <StocksPage type="segment" value="items" />,
          },
        ],
      },
    ],
  },
  {
    role: 4,
    type: "collapse",
    name: "Distribution",
    key: "distribution",
    icon: <Icon fontSize="medium">shopping_basket</Icon>,
    collapse: [
      {
        role: 4,
        name: "Manufacture (%)",
        key: "distribution-manufacture",
        collapse: [
          {
            role: 4,
            name: "NUM Dis (%)",
            key: "distribution-manufacture-numeric",
            route: "/distribution/manufacture/numeric",
            component: <DistributionPage type="manufacture" value="numeric" />,
          },
          {
            role: 4,
            name: "NUM OOS (%)",
            key: "distribution-manufacture-numericoos",
            route: "/distribution/manufacture/numericoos",
            component: <DistributionPage type="manufacture" value="numericoos" />,
          },
          {
            role: 4,
            name: "WGT Dis (%)",
            key: "distribution-manufacture-weighted",
            route: "/distribution/manufacture/weighted",
            component: <DistributionPage type="manufacture" value="weighted" />,
          },
          {
            role: 4,
            name: "WGT OOS (%)",
            key: "distribution-manufacture-weightedoos",
            route: "/distribution/manufacture/weightedoos",
            component: <DistributionPage type="manufacture" value="weightedoos" />,
          },
        ],
      },
      {
        role: 4,
        name: "Brand",
        key: "distribution-brand",
        collapse: [
          {
            role: 4,
            name: "NUM Dis (%)",
            key: "distribution-brand-numeric",
            route: "/distribution/brand/numeric",
            component: <DistributionPage type="brand" value="numeric" />,
          },
          {
            role: 4,
            name: "NUM OOS (%)",
            key: "distribution-brand-numericoos",
            route: "/distribution/brand/numericoos",
            component: <DistributionPage type="brand" value="numericoos" />,
          },
          {
            role: 4,
            name: "WGT Dis (%)",
            key: "distribution-brand-weighted",
            route: "/distribution/brand/weighted",
            component: <DistributionPage type="brand" value="weighted" />,
          },
          {
            role: 4,
            name: "WGT OOS (%)",
            key: "distribution-brand-weightedoos",
            route: "/distribution/brand/weightedoos",
            component: <DistributionPage type="brand" value="weightedoos" />,
          },
        ],
      },
      {
        role: 4,
        name: "Segment",
        key: "distribution-segment",
        collapse: [
          {
            role: 4,
            name: "NUM Dis (%)",
            key: "distribution-segment-numeric",
            route: "/distribution/segment/numeric",
            component: <DistributionPage type="segment" value="numeric" />,
          },
          {
            role: 4,
            name: "NUM OOS (%)",
            key: "distribution-segment-numericoos",
            route: "/distribution/segment/numericoos",
            component: <DistributionPage type="segment" value="numericoos" />,
          },
          {
            role: 4,
            name: "WGT (%)",
            key: "distribution-segment-weighted",
            route: "/distribution/segment/weighted",
            component: <DistributionPage type="segment" value="weighted" />,
          },
          {
            role: 4,
            name: "WGT OOS (%)",
            key: "distribution-segment-weightedoos",
            route: "/distribution/segment/weightedoos",
            component: <DistributionPage type="segment" value="weightedoos" />,
          },
        ],
      },
    ],
  },
  {
    role: 4,
    type: "collapse",
    name: "Price",
    key: "price",
    icon: <Icon fontSize="medium">monetization_on</Icon>,
    collapse: [
      {
        role: 4,
        name: "Manufacture",
        key: "price-manufacture",
        route: "/price/manufacture",
        component: <PricePage type="manufacture" />,
      },
      {
        role: 4,
        name: "Brand",
        key: "price-brand",
        route: "/price/brand",
        component: <PricePage type="brand" />,
      },
      {
        role: 4,
        name: "Segment",
        key: "price-segment",
        route: "/price/segment",
        component: <PricePage type="segment" />,
      },
      {
        role: 4,
        name: "Region",
        key: "price-region",
        route: "/price/region",
        component: <PricePage type="region" />,
      },
      {
        role: 4,
        name: "Channel",
        key: "price-channel",
        route: "/price/channel",
        component: <PricePage type="channel" />,
      },
    ],
  },
  {
    role: 4,
    type: "collapse",
    name: "Facing",
    key: "facing",
    icon: <Icon fontSize="medium">store</Icon>,
    collapse: [
      {
        role: 4,
        name: "Manufacture",
        key: "facing-manufacture",
        route: "/facing/manufacture",
        component: <FacePage type="manufacture" />,
      },
      {
        role: 4,
        name: "Brand",
        key: "facing-brand",
        route: "/facing/brand",
        component: <FacePage type="brand" />,
      },
      {
        role: 4,
        name: "Segment",
        key: "facing-segment",
        route: "/facing/segment",
        component: <FacePage type="segment" />,
      },
      {
        role: 4,
        name: "Region",
        key: "facing-region",
        route: "/facing/region",
        component: <FacePage type="region" />,
      },
      {
        role: 4,
        name: "Channel",
        key: "facing-channel",
        route: "/facing/channel",
        component: <FacePage type="channel" />,
      },
    ],
  },
  {
    role: 4,
    type: "collapse",
    name: "Products",
    key: "products",
    route: "/products",
    component: <ProductsPage />,
    icon: <Icon fontSize="medium">receipt_long</Icon>,
    noCollapse: true,
  },
  {
    role: 4,
    name: "Product",
    key: "products",
    route: "/products/:id",
    component: <ProductPage />,
  },
  {
    role: 4,
    type: "divider",
    key: "divider-1",
  },
  {
    role: 2,
    type: "collapse",
    name: "Settings",
    key: "settings",
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [
      {
        role: 2,
        name: "Users",
        key: "users",
        route: "/users",
        component: <UsersPage />,
      },
      {
        role: 1,
        name: "Companies",
        key: "settings-companies",
        route: "/companies",
        component: <CompaniesPage />,
      },
      {
        role: 2,
        name: "Logs",
        key: "settings-logs",
        route: "/settings/logs",
        component: <LogsPage />,
      },
    ],
  },
  {
    role: 4,
    type: "collapse",
    name: "Manual",
    key: "manual",
    route: "/manual",
    component: <ManualPage />,
    icon: <Icon fontSize="medium">receipt_long</Icon>,
    noCollapse: true,
  },
  {
    role: 4,
    name: "New Company",
    key: "company-new",
    route: "/companies/new",
    component: <NewCompany />,
  },
  {
    role: 4,
    name: "Add User",
    key: "users-new",
    route: "/users/new",
    component: <NewUser />,
  },
];

export default routes;
