import { useState, useEffect } from "react";
import axios from "axios";
import API from "utils/API";
import ImageCell from "layouts/dashboards/sales/components/ImageCell";
import config from "config";
import ColorCell from "layouts/dashboards/sales/components/ColorCell";
import useToken from "useToken";

export default () => {
  const [data, setData] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { token, setToken } = useToken();

  const formData = new FormData();
  formData.append("session_key", token);

  useEffect(() => {
    setLoading(true);
    API.post(`get_companies`, formData)
      .then((res) => {
        setLoading(false);

        if (res.data.companies.length > 0) {
          const newArr = res.data.companies.map((val) => {
            const image = config.imageLogo + val.logo;
            return {
              id: val.id,
              name: val.name,
              logo: <ImageCell image={image} name={val.name} />,
              status: val.status,
              color: val.color,//<ColorCell color={val.color} />,
              expire_date: val.expire_date,
            };
          });

          console.log(newArr);
          setData(newArr);
        }
        setErrorMessage(null);
        /*
         */
      })
      .catch((err) => {
        setLoading(false);
        setData([]);
        setErrorMessage(err.message);
      });
  }, []);

  return data;
};
