/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { BrowserRouter as Router, Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import Grid from "@mui/material/Grid";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import reportsLineChartData from "layouts/dashboards/analytics/data/reportsLineChartData";
import { useMaterialUIController } from "context";
import useUsers from "hooks/useUsers";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";
import DetailButton from "../products/components/DetailButton";

function UsersPage() {
  const table = {
    columns: [
      { Header: "Username", accessor: "user_name", width: "20%" },
      { Header: "Firstname", accessor: "first_name", width: "20%" },
      { Header: "Lastname", accessor: "last_name", width: "20%" },
      { Header: "Role", accessor: "role_name", width: "20%" },
      { Header: "Company", accessor: "company_name", width: "20%" },
      {
        Header: "action",
        accessor: "user_id",
        // Cell: ({ value }) <DetailButton id={value} />,
      },
    ],
    rows: [],
  };
  const data = useUsers();
  table.rows = data.users;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Users
            </MDTypography>
            <Link to="/users/new">
              <MDButton variant="gradient" color="info">
                new user
              </MDButton>
            </Link>
          </MDBox>
          {data.users && <DataTable table={table} canSearch />}
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default UsersPage;
