function toNum(value) {
  if (!Number.isNaN(Number(value))) {
    // return Number(value).toLocaleString(undefined, {maximumFractionDigits: 1});
    return Number(value).toLocaleString();
  }
  return value;
}

function toNum2(value) {
  return Number(value).toLocaleString();
}

export { toNum, toNum2 };
