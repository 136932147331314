/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDBadgeDot from "components/MDBadgeDot";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DefaultStatisticsCard from "examples/Cards/StatisticsCards/DefaultStatisticsCard";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import HorizontalBarChart from "examples/Charts/BarCharts/HorizontalBarChart";
import DataTable from "examples/Tables/DataTable";

// Data
import useDashboardSales from "hooks/useDashboardSales";

import { useMaterialUIController } from "context";
import { RotatingLines } from "react-loader-spinner";
import { toNum } from "helpers";

function DashboardDistribution({type}) {
  const [controller] = useMaterialUIController();
  const { period, category } = controller;

  const [data, loading] = useDashboardSales(period, category, type);

  const salesChart = {
    labels: data.cols,
    datasets: [
      {
        label: "NUM Distribution",
        color: "info",
        data: data.dis_num,
      },
      {
        label: "WGT Distribution",
        color: "dark",
        data: data.dis_wgt,
      },
    ],
  };

  const brandShare = {
    labels: data.brand_share_label,
    datasets: [
      {
        label: "Top 10 brands share",
        color: "dark",
        data: data.brand_share_percent,
        backgroundColor: data.brand_colors,
      },
    ],
  };

  const topBrands = {
    columns: [
      { Header: "Brand", accessor: "brand", width: "30%" },
      { Header: "Value", accessor: "value", width: "30%" },
    ],
    rows: data.brand_value,
  };

  const manufactureShare = {
    labels: data.manu_share_label,
    datasets: [
      {
        label: "Top 10 manufactures share",
        color: "dark",
        data: data.manu_share_percent,
        backgroundColor: data.manu_colors,
      },
    ],
  };

  const topManufactures = {
    columns: [
      { Header: "Manufacture", accessor: "manufacture", width: "30%" },
      { Header: "Value", accessor: "value", width: "30%" },
    ],
    rows: data.manu_value,
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="96"
          visible={loading}
        />
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} lg={12}>
              <DefaultLineChart
                title="Facing"
                description={
                  <MDBox display="flex" justifyContent="space-between">
                    <MDBox display="flex" ml={-1}>
                      <MDBadgeDot color="info" size="sm" badgeContent="NUM Distribution" />
                      <MDBadgeDot color="dark" size="sm" badgeContent="WGT Distribution" />
                    </MDBox>
                  </MDBox>
                }
                chart={salesChart}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <HorizontalBarChart title="Top 10 manufactures share" chart={manufactureShare} />
            </Grid>
            <Grid item xs={12} lg={6}>
              {data.manu_value && (
                <Card>
                  <MDBox pt={3} px={3}>
                    <MDTypography variant="h6" fontWeight="medium">
                      Top 10 manufactures sales
                    </MDTypography>
                  </MDBox>
                  <MDBox py={1}>
                    <DataTable
                      table={topManufactures}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      isSorted={false}
                      noEndBorder
                    />
                  </MDBox>
                </Card>
              )}
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <HorizontalBarChart title="Top 10 brands share" chart={brandShare} />
            </Grid>
            <Grid item xs={12} lg={6}>
              {data.brand_value && (
                <Card>
                  <MDBox pt={3} px={3}>
                    <MDTypography variant="h6" fontWeight="medium">
                      Top 10 brands sales
                    </MDTypography>
                  </MDBox>
                  <MDBox py={1}>
                    <DataTable
                      table={topBrands}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      isSorted={false}
                      noEndBorder
                    />
                  </MDBox>
                </Card>
              )}
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
DashboardDistribution.defaultProps = {
  type: "sales"
};

DashboardDistribution.propTypes = {
  type: PropTypes.string,
};

export default DashboardDistribution;
