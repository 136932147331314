/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import { Link } from "react-router-dom";

function DetailButton({ id }) {
  return (
    <MDTypography>{id && <Link to={{ pathname: `/product/${id}` }}>Detail</Link>}</MDTypography>
  );
}
DetailButton.defaultProps = {
  id: "",
};

// Typechecking props for the ProductCell
DetailButton.propTypes = {
  id: PropTypes.string,
};

export default DetailButton;
