import axios from "axios";
import config from "config";
import useToken from "useToken";

const API = axios.create({
  baseURL3: "https://randomuser.me/api/",
  // baseURL: "http://rspa.local/yr/rspa/web/public/api/index.php/api/",
  baseURL2: "https://0c83-192-82-81-31.ngrok.io/yr/rspa/web/public/api/index.php/api/",
  baseURL4: "https://sg.media-imdb.com/suggests/h/",
  baseURL: config.api,
  responseType: "json",
});

// API.defaults.headers.common["Authorization"] = token;
/*

const { token, setToken } = useToken();
  headers: {
    common: {
      Authorization: token,
    },
  },
API.interceptors.request.use((config) => {
  config.headers.common["Authorization"] = token;
  return config;
});
*/
API.interceptors.response.use(
  (response) => response,
  (error) => {
    // whatever you want to do with the error
    if (error.response.status === 404) {
      console.log("<<< 404");
      // window.top.location = "";
    } else if (error.response.status === 401) {
      console.log("<<< 401");
      if(window.location.pathname != "/authentication/sign-in/basic") {
        window.top.location = "/authentication/sign-in/basic";
      }
    }
    throw error;
  }
);

export default API;
