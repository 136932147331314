/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import { useEffect, useState } from "react";
import { useMaterialUIController } from "context";

function UserName() {
  const [username, setUsername] = useState("User");
  const [controller, dispatch] = useMaterialUIController();
  const { user } = controller;

  useEffect(() => {
    setUsername(user.name);
  }, [user]);

  return (
    <MDTypography variant="button" fontWeight="regular" style={{ color: "#FFF" }}>
      {username}
    </MDTypography>
  );
}

export default UserName;
