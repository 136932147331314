/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import Grid from "@mui/material/Grid";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import reportsLineChartData from "layouts/dashboards/analytics/data/reportsLineChartData";
import useStocks from "hooks/useStocks";
import { useMaterialUIController } from "context";
import { RotatingLines } from "react-loader-spinner";

function StocksPage({ type, value }) {
  const [controller, dispatch] = useMaterialUIController();
  const { sales, tasks } = reportsLineChartData;
  const { period, category } = controller;

  const title = `Stocks ${type}`;
  const title2 = `Stocks ${value} (%) / ${type}`;
  const [data, loading] = useStocks(type, value, period, category);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="96"
          visible={loading}
        />
        <Grid item>
          <MDBox mb={3}>
            {data.chart && (
              <ReportsLineChart
                color="success"
                title={title}
                description=""
                date=""
                chart={data.chart}
              />
            )}
          </MDBox>
        </Grid>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              {title2}
            </MDTypography>
          </MDBox>
          {data.table && <DataTable table={data.table} canSearch />}
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
StocksPage.defaultProps = {
  type: "manufacture",
  value: "volume",
};

StocksPage.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
};

export default StocksPage;
